import styled from "styled-components";
import { RowStyled } from "components/Styled";
import { colors, mobile } from "styles";
// import { isMobile } from "react-device-detect";

export const Row = styled(RowStyled)`
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 60px;
  flex-direction: ${mobile ? "column" : "row"};
  align-items: ${mobile ? "center" : "initial"};
`;

export const Line = styled.div`
  width: ${mobile ? "200px" : "4px"};
  border-radius: 2px;
  height: ${mobile ? "4px" : "80px"};
  background-color: ${colors.brown};
  margin: ${mobile ? "30px auto" : "auto 30px"};
`;
export const ValoresStyled = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  height: ${props => (props.onlyValue ? "auto" : "160px:")};
  position: relative;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    text-align: center;
    flex: 1;
  }
  h3 {
    font-size: 60px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .cifrao,
  .cents {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents {
    margin-left: ${props => (props.onlyValue ? "130px" : "110px")};
  }
  .cifrao {
    margin-left: ${props => (props.onlyValue ? "-65px" : "-55px")};
    margin-bottom: 53px;
  }

  .obs {
    font-size: 12px;
    line-height: 0.3;
    white-space: nowrap;
  }

  .btn-valores {
    margin-bottom: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 100%;
  .police-icon {
    height: 135px;
    margin-left: ${props => (props.type ? "20px" : "0")};
  }
  .police-desc {
    font-weight: 600;
    font-size: 24px;
  }
`;
