import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "navigation/Navbar/";
import { Content, Row, Capa, Select } from "./styles";
import { images, screenHeight } from "styles";
import Button from "components/Button";
import Footer from "components/Footer";
//import ModalPagseguro from "components/ModalPagSeguro";
import ModalPrecos from "components/ModalPrecos";
import Infracao from "./components/Infracao";
import Loader from "components/Loading";
import ReactGA from "react-ga";

class Recursos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "0",
      //showPagSeguro: false,
      showPrecos: false,
      loader: false
    };

    //this.showPagSeguro = this.showPagSeguro.bind(this);
    this.showPrecos = this.showPrecos.bind(this);
  }

  renderTipo = () => {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.type !== this.props.type) {
      this.setState({ type: nextProps.type });
    }
  }
  componentDidMount() {
    ReactGA.initialize("UA-000000-01");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = url.searchParams.get("type");
    console.log(params);
    if (params !== null) {
      console.log("teste", params);
      this.setState({ type: params });
      //window.scrollTo(0, 0);
      this.renderTipo();
    } else {
      window.scrollTo(0, 0);
    }
  }
 /* 
  showPagSeguro(status) {
    this.setState(state => ({
      showPagSeguro: !state.showPagSeguro
    }));
  }
  */
  showPrecos(status) {
    this.setState(state => ({
      showPrecos: !state.showPrecos
    }));
  }

  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };
  setType = event => {
    this.setState({ type: null, loader: true });
    let e = event.target.value;
    setTimeout(
      function() {
        this.setState({ type: e, loader: false });
      }.bind(this),
      1000
    );
  };

  scrollTop = () => {
    window.scroll(0, screenHeight);
  };

  render() {
    return (
      <Fragment>
        <Navbar page="/recursos" />
        <Content className="content">
          <Capa>
            <h1>Recursos</h1>
            <h2>
              Recursos de Infrações de Trânsito e <br />
              Suspensão/Cassação da CNH
            </h2>
            <img
              alt="Mouse"
              onClick={() => this.scrollTop()}
              src={images.mousePrimary}
            />
          </Capa>

          <h3>Como Recorrer?</h3>
          <span>
            O processo de solicitação através da Adeus Multas é simples e
            descomplicado. Selecione um tipo de recurso para saber mais sobre
            ele. A partir daí basta seguir o passo a passo para encaminhar o seu
            recurso.
          </span>
          <span className="info-text">
            Caso tenha dúvidas sobre a sua infração,{" "}
            <Link className="link-text" to="/faq">
              clique aqui
            </Link>{" "}
            para acessar nossa seção de Perguntas Frequentes.
          </span>
          <span className="info-text">
            Ao contratar, você concorda com nosso{" "}
            <Link className="link-text" to="../externalfiles/contratoservico.pdf" target="_blank">
              Contrato de Prestação de Serviços
            </Link>{" "}
            e com nossa
            <Link className="link-text" to="../externalfiles/politicadeprivacidade.pdf" target="_blank">
              Política de Privacidade
            </Link>{" "}.
          </span>

          <Row style={{ justifyContent: "center", marginTop: 30 }}>
            <Select value={this.state.type} onChange={this.setType}>
              <option value="0">Selecione um recurso...</option>
              <option value="1">Recurso de infração de trânsito</option>
              <option value="2">Recurso de suspensão/cassação da CNH</option>
            </Select>
          </Row>
          {(this.state.type === "1" || this.state.type === "2") && (
            <Infracao
              type={this.state.type}
              showModalPreco={this.showPrecos}
            />
          )}
          {this.state.type === "0" && (
            <div className="box-info">
              <p className="desc">Selecione um recurso para ver mais</p>
              <p className="desc">...</p>
            </div>
          )}
          {this.state.loader && <Loader />}
          <Footer />
        </Content>
      
        <ModalPrecos action={this.showPrecos} show={this.state.showPrecos} />
      </Fragment>
    );
  }
}

export default Recursos;
