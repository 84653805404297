import React, { Component } from "react";

import { ContentPassos, LinePassos } from "./styles";

export default class Passos extends Component {
  render() {
    const { number } = this.props;
    return (
      <ContentPassos>
        <h2>{number}</h2>
        <LinePassos />
      </ContentPassos>
    );
  }
}
