import React from "react";
import styled from "styled-components";
import { colors, mobile } from "styles";

export const ContentStyled = styled.div`

  
  h1{
    font-size: ${mobile ? "40px" : "70px"};
    font-weight: 600;
    color:${colors.primary};
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  h2{
    font-size: ${mobile ? "28px" : "40px"};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 15px;
    color:${colors.dark};
  }
  
  
  h3{
    font-size: ${mobile ? "24px" : "34px"};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 15px;
    color:${colors.secundary};
    text-align:center;
  }

  h4{
    font-size: ${mobile ? "20px" : "28px"};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 15px;
    color:${colors.secundary};
    text-align:center;
  }
  
  h5{
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 15px;
    color:${colors.dark};
  }
  
  span, p{
    font-size: ${mobile ? "18px" : "22px"};
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
    color:${colors.dark};
  }
  
  
  
    flex:1;
    margin-top:${mobile ? "80px" : "initial"};
    width: calc(100% - (${mobile ? `30px` : 0}));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: ${mobile ? `15px 15px 0 15px` : `30px 30px 0 30px`} ;
    margin-left: ${mobile ? 0 : "260px"};
    
  
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
`;
