import styled from "styled-components";
import { ContentStyled, RowStyled } from "components/Styled";
import { colors, mobile } from "styles";

export const Container = styled(ContentStyled)`
  flex: 1;
  margin-left: 0 !important;
  margin-top: 60px;
  padding-bottom: 60px;

  h3 {
    color: ${colors.dark};
  }
  span {
    max-width: 678px;
    margin-bottom: 30px;
  }
`;
