import React, { Component, Fragment } from "react";
import {
  ButtonStyle,
  ButtonBordered,
  ButtonStyleTo,
  ButtonBorderedTo
} from "./styles";

class Button extends Component {
  render() {
    const { title, onClick, type, bordered, to, style, className } = this.props;
    return (
      <Fragment>
        {!bordered && !to && (
          <ButtonStyle
            className={className}
            onClick={onClick}
            type={type}
            style={style}
          >
            <label>{title}</label>
          </ButtonStyle>
        )}

        {bordered && !to && (
          <ButtonBordered
            className={className}
            onClick={onClick}
            type={type}
            style={style}
          >
            <label>{title}</label>
          </ButtonBordered>
        )}

        {!bordered && to && (
          <ButtonStyleTo
            className={className}
            to={to}
            type={type}
            style={style}
          >
            <label>{title}</label>
          </ButtonStyleTo>
        )}

        {bordered && to && (
          <ButtonBorderedTo
            className={className}
            to={to}
            type={type}
            style={style}
          >
            <label>{title}</label>
          </ButtonBorderedTo>
        )}
      </Fragment>
    );
  }
}
export default Button;
