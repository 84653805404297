import React, { Component } from "react";
import { Container, ModalBox, Row } from "./styles";
import { images } from "styles";

export default class ModalAlerta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }
  render() {
    const { show, action } = this.props;
    return (
      <Container pose={show ? "visible" : "hidden"}>
        <ModalBox>
          <Row>
            <h3>Aproveite o Cashback de 30%!</h3>
            <img
              onClick={() => action()}
              className="ico-close"
              alt="close"
              src={images.close}
            />
          </Row>
          <Row className="body">
            <span>
              Caros clientes.<br/>
              Até o dia 05/07/2022, pagando com o app PicPay e usando o cupom "SINTOSUAFALTA", você receberá 30% de cashback (limitado a uma compra e R$15,00 de cashback por CPF).
              <br/><br/>Ative o cupom antes da compra acessando sua "Carteira" e clicando em "Inserir cupom promocional".
            </span>
          </Row>
        </ModalBox>
      </Container>
    );
  }
}
