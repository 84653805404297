import React, { Component, Fragment } from "react";
import Navbar from "navigation/Navbar/";
import { Content, Row, Capa, Card, Step, Line, Select } from "./styles";
import { images, screenHeight } from "styles";
import Toggle from "components/Toggle";
import Button from "components/Button";
import Footer from "components/Footer";
import OutrosContatos from "components/OutrosContatos";
import Caroussel from "components/Caroussel";
import ModalAlerta from "components/ModalAlerta";
import ReactGA, { ga } from "react-ga";
import styledComponents from "styled-components";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      show: false,
      list: [
        {
          icon: images.tie,
          alt: "Gravata",
          desc:
            "Somos Especialistas em Recursos de Infração de Trânsito e Suspensão e Cassação da CNH."
        },
        {
          icon: images.rule,
          alt: "Régua",
          desc:
            "Não Trabalhamos com Modelos: Analisamos detalhadamente seu caso e confeccionamos um recurso sob medida para cada situação."
        },
        {
          icon: images.rocket,
          alt: "Foguete",
          desc:
            "Nosso lema é agilidade, e nossa missão é garantir a sua tranqüilidade."
        }
      ]
    };

    this.showAlerta = this.showAlerta.bind(this);
  }

  showAlerta() {
    this.setState(state => ({
      showAlerta: !state.showAlerta
    }));
  }

  setType = event => {
    this.setState({ type: event.target.value });
  };

    componentDidMount() {
    ReactGA.initialize("G-WLMMB55H2Y");
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
    const date = new Date();
    const datainic = new Date('06/07/2022');
    const datafim = new Date('07/05/2022');
    if ((date >= datainic) && (date <= datafim)) {
      window.addEventListener('load', this.showAlerta); 
    }

  }



  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };
  scrollTop = () => {
    window.scroll(0, screenHeight);
  };

  renderCard = lista => (
    <Card>
      <img alt={lista.alt} src={lista.icon} />
      <span>{lista.desc}</span>
    </Card>
  );

  renderSteps = () => (
    <Row
      style={{
        alignItems: "stretch",
        justifyContent: "space-between",
        marginBottom: 60,
        marginTop: 30
      }}
    >
      <Step className="first">
        <Row>
          <h3>1º Passo</h3>
        </Row>
        <Row style={{ flex: 1, alignItems: "center" }}>
          <Select value={this.state.type} onChange={this.setType}>
            <option value="0">Selecione um recurso...</option>
            <option value="1" selected>
              Recurso de infração de trânsito
            </option>
            <option value="2">Recurso de suspensão/cassação da CNH</option>
          </Select>
        </Row>
        <Row style={{ justifyContent: "flex-end" }}>
          <Button
            title="Avançar"
            className="btn-step"
            to={{ pathname: "/recursos", search: "?type=" + this.state.type }}
          />
        </Row>
      </Step>
      <Step className="second">
        <h3>2º Passo</h3>
        <Line className="first-line" />
        <Line />
        <Line />
        <Line />
        <Line className="first-line" />
        <Line />
        <Line />
        <Line />
        <Line className="first-line" />
        <Line />
        <Line />
      </Step>
    </Row>
  );

  render() {
    return (

      <Fragment>
        <Navbar page="/" />
        <Content className="content">
          <Capa>
            <span className="desc-intro-text">
              Infração de Trânsito
              <br />
              Suspensão e Cassação da CNH
            </span>
            <ModalAlerta  />
            <h1>Seu Recurso em 24h</h1>
            <h2>A partir de R$49,90.</h2>
            <Row className="capa-btn-group">
              <Button
                title="Faça uma consulta gratuita!"
                onClick={() => this.action("https://wa.me/5554984286630")}
              />
            </Row>

            <img
              alt="mouse"
              onClick={() => this.scrollTop()}
              src={images.mousePrimary}
            />
          </Capa>
          <h3>Pode Confiar</h3>
          <Row className="card">
            {this.state.list.map(lista => this.renderCard(lista))}
          </Row>

          <h3>O que dizem nossos clientes?</h3>
          <Caroussel />

          <h3>Nós te guiamos durante o processo</h3>
          <span>
            Criamos um passo a passo detalhado sem complicações de como proceder
            com o seu recurso.
          </span>
          {this.renderSteps()}
          <h3>Blog</h3>
          <span>
            Conteúdo diverso sobre normas, curiosidades, atualizações e notícias
            sobre tudo o que envole o trânsito.
          </span>
          <Button
            title="Acessar o blog"
            onClick={() => this.action("/blog", "_self")}
          />
          <Footer />
        </Content>
        <ModalAlerta
          action={this.showAlerta}
          show={this.state.showAlerta}
        />
      </Fragment>
    );
  }
}
export default Home;
