import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Content, Line, Row, Card, Select } from "./styles";
import { isMobile } from "react-device-detect";
import Button from "components/Button";

export default class Pagamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pay: "0"
    };
  }
  action = (url, target) => {
    if (url !== "0") window.open(url, target ? target : "_blank");
  };

  setType = event => {
    this.setState({ pay: event.target.value });
    window.open(event.target.value, "_blank");
  };

  render() {
    const { showModal, type } = this.props;
    return (
      <Content>
        <h3>Pagamento</h3>
        <span>
          Aceitamos duas formas de pagamento. Mercado Pago (cartão de crédito e boleto) ou
          Depósito/Transferência bancária. Ambas são seguras e garantem a
          entrega do serviço. Caso queira se sentir mais seguro você pode:
        </span>

        <Row style={{ flexWrap: "wrap" }}>

          <Button
            title="Fazer uma consulta gratuita"
            bordered
            onClick={() => this.action("https://wa.me/5554984286630")}
          />
        </Row>
        <Row className="card">
          <Card>
            <h4>PicPay</h4>
            <span className="body">
              <Button
          title="Pague pelo PicPay"
          bordered
          onClick={() =>
            this.action(
              "https://app.picpay.com/payment?type=store&hash=Fh2GxAp1gXn6AvhC"
            )
          }
              />
            </span>
            <h4>Mercado Pago</h4>
            <span className="body">
              Selecione abaixo o tipo de infração que deseja pagar. Você será
              redirecionado para o site seguro do Mercado Pago. Durante o processo
              de pagamento, você poderá alterar a quantidade de recursos que
              deseja comprar.
            </span>
            {type === "1" && (
              <Fragment>
                <form name="infracoes">
                                   
                  <Select
                    value={this.state.pay}
                    onChange={this.setType}
                    class="azul"
                  >
                    <option value="0">Selecione uma opção...</option>
                    <option value="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=221165344-46c30b53-9e1f-4a69-ae78-1a8f830848d8">
                      Infrações leves e médias
                    </option>
                    <option value="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=221165344-45e051e5-a318-49b9-9408-9b68c09af125">
                      Infrações graves
                    </option>
                    <option value="https://www.mercadopago.com.br/checkout/v1/redirect?pref_id=221165344-2b6ed832-f5b5-4a27-a41e-af985ad37989">
                      Infrações gravíssimas de R$ 293,47
                    </option>
                    <option value="https://mpago.la/2UUnXca">
                      Infrações gravíssimas de até R$ 880,41
                    </option>
                    <option value="https://mpago.la/2DTohg7">
                      Infrações gravíssimas de R$ 1.467,35
                    </option>
                    <option value="https://mpago.la/25f9joc">
                      Infrações gravíssimas de R$ 2.934,70
                    </option>
                  </Select>
                </form>
                {isMobile && (
                  <Button
                    title="Efetuar o Pagamento"
                    className="btn-mobile"
                    onClick={() => this.action(this.state.pay)}
                  />
                )}
              </Fragment>
            )}
            {type === "2" && (
              <Button
                title="Efetuar o Pagamento"
                className="btn-mobile"
                onClick={() => this.action("https://mpago.la/2vK8maN")}
              />
            )}
          </Card>
          <Card>
          <h4>
              Transferência ou Pix (Chave: 31.580.677/0001-70)
              <br />
            </h4>
            <span>
              <p>
                <b>Banco:</b> 0260 - Nu Pagamentos S.A (NuBank)
              </p>
              <p>
                <b>Agência:</b> 0001
              </p>
              <p>
                <b>Conta:</b> 80740601-0
              </p>
              <p>
                <b>Titular:</b> Analice dos Santos Escobar 00727027026
              </p>
              <p>
                <b>CNPJ:</b> 31.580.677/0001-70 (Chave Pix)
              </p>
              <span className="info-text">
                Caso efetue o pagamento através de outro banco, solicite o CPF ,{" "}
                <Button
                  bordered
                  className="link-text"
                  onClick={() =>
                    this.action(
                      "https://wa.me/5554984286630",
                      "_blank"
                    )
                  }
                  title="clique aqui"
                />
                .
              </span>
              <p>
                Envie o comprovante de depósito ou transferência para o e-mail{" "}
                <b className="blue">contato@adeusmultas.com</b> informando seu{" "}
                <span className="blue">NOME</span> e{" "}
                <span className="blue">CPF</span> para que possamos localizar o
                pedido e enviar o recurso.
              </p>
            </span>
          </Card>
        </Row>
      </Content>
    );
  }
}
