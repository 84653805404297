import React, { Component, Fragment } from "react";
import Navbar from "navigation/Navbar/";
import { Content, Row } from "./styles";
import Toggle from "components/Toggle";
import Button from "components/Button";
import Footer from "components/Footer";
import OutrosContatos from "components/OutrosContatos";
import ReactGA from "react-ga";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqList: [
        {
          question: "Como funciona o recurso? É garantido?",
          answer: (
            <span>
              Os recursos apresentados são técnicos e embasados na legislação,
              com ampla fundamentação de mérito e jurídica. Eles levam em conta
              inúmeros fatores, como visibilidade dos aparelhos aplicadores de
              infração, visibilidade da sinalização de trânsito, prontuário do
              condutor, se houve autuação por agente de trânsito e se o mesmo
              foi identificado, condições do veículo e outras variáveis que
              interferem no sucesso do recurso.{" "}
              <p>
                Embora as chances de reverter a maior parte das infrações sejam
                altas (as chances podem diminuir um pouco conforme a gravidade
                da infração), não há como se garantir o sucesso do recurso. Isso
                porque nós não temos controle ou influência sobre o órgão
                julgador.{" "}
                <u>
                  Qualquer um que disser que o recurso é 100% garantido estará
                  mentindo para você.
                </u>
              </p>
            </span>
          )
        },
        {
          question: "Como funciona a conversão de multa em advertência?",
          answer: (
            <span>
              A conversão de multa em advertência serve para converter multas
              por infrações leves e médias em advertência. Com isso, o condutor
              não paga a multa e nem recebe pontuação em sua CNH. A conversão só
              é possível se o condutor não for reincidente na mesma infração nos
              últimos 12 meses.
            </span>
          )
        },
        {
          question: "Como contrato e faço o pagamento do recurso?",
          answer: (
            <span>
              A contratação é feita preenchendo o formulário na página
              www.adeusmultas.com/recursos. Siga as orientações da página para
              preencher o formulário.
              <p>
                Já o pagamento pode ser feito pelo Mercado Pago (Cartão de crédito ou boleto) ou depósito
                em conta. Na mesma página em que consta o formulário há os
                botões para pagamento e os dados bancários. Basta clicar no
                botão selecionado e você será redirecionado à página de
                pagamento. Ou, se preferir, faça um depósito ou transferência e
                nos envie o comprovante de pagamento.
              </p>
            </span>
          )
        },
        {
          question: "Onde está localizado o número do auto de infração?",
          answer:
            "Geralmente este número está localizado no canto superior esquerdo da Notificação."
        },
        {
          question:
            "Recebi a Notificação de Infração mas não sou o condutor, apenas o proprietário. O que faço?",
          answer:
            "Primeiramente, você deve destacar o Formulário de Identificação de Condutor e enviar para o endereço indicado na Notificação, devidamente preenchido. Depois disso, quando o condutor receber a notificação, o recurso deverá ser apresentado."
        },
        {
          question:
            "Recebi a Notificação de Aplicação de Penalidade, mas não recebi a Notificação de Infração antes disso. Isso pode ocorrer?",
          answer:
            "Pode ocorrer, mas é preferível verificar no site do Detran de seu Estado para ver se a multa realmente existe. Isso porque muitas quadrilhas aplicam golpes enviando multas falsas com boleto para pagamento. Descarte a possibilidade de se tratar um golpe antes de pagar ou entrar com recurso."
        },
        {
          question:
            "O que é o Requerimento de Defesa/Recurso que na Notificação informa que devo enviar junto para o órgão?",
          answer:
            "Se trata de um formulário simples disponibilizado no site do órgão autuador. Contudo, esse formulário é OPCIONAL e acima de tudo simplório, não permitindo uma argumentação mais aprofundada. O recurso confeccionado pela Adeus Multas substitui o Requerimento de Defesa/Recurso para todos os efeitos."
        },
        {
          question: "Como preencher o Aviso de Recebimento?",
          answer:
            'O AR (Aviso de Recebimento) deve ser preenchido com os seus dados no campo Remetente e com o nome e endereço do órgão que irá julgar o recurso. No campo "Declaração de Conteúdo" escreva "Defesa Prévia/Recurso" e o coloque o número do Auto de Infração de Trânsito (AIT).'
        },
        {
          question: "Para onde devo enviar o Recurso?",
          answer:
            "Toda notificação, seja ela de infração ou de aplicação de penalidade, constará o nome do órgão que lhe envio a carta, bem como terá um campo, geralmente com letras menores, com informações com endereço de postagem do recurso e o endereço para caso você deseje protocolar pessoalmente o mesmo. Utilize o endereço de postagem para enviar o recurso."
        },
        {
          question:
            "Vocês fazem recurso contra processo de suspensão/cassação do direito de dirigir?",
          answer:
            "Também confeccionamos esse tipo de recurso. Você pode preencher nosso formulário específico com os dados do processo de suspensão ou cassação do seu direito de dirigir para que possamos lhe orientar."
        }
      ]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-000000-01");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Fragment>
        <Navbar page="/faq" />
        <Content className="content">
          <h1>FAQ</h1>
          <h2>
            Tem alguma dúvida? As mais frequentes, você consegue a resposta
            aqui!
          </h2>
          <h3>Perguntas Frequentes</h3>
          {this.state.faqList.map((faq, index) => (
            <Toggle key={index} index={index + 1} item={faq} />
          ))}
          <OutrosContatos
            title="Outros"
            desc="Caso ainda tenha dúvidas, ou deseja saber mais sobre o assunto, acesse e nos siga em nosso canais."
          />
          <Footer />
        </Content>
      </Fragment>
    );
  }
}

export default Faq;
