import { importImages } from "utils";
import { isMobile, isTablet } from "react-device-detect";

const screenwidth = window.innerWidth;
export const screenHeight = window.innerHeight;

const image = importImages(
  require.context("assets/images", false, /\.(png|jpe?g|svg)$/)
);

const isTabletBig = screenwidth < 769;
export const mobile = isTabletBig && isMobile;

export const colors = {
  white: "#FFFFFF",
  black: "#202020",
  dark: "#53524F",
  primary: "#0d3b66",
  secundary: "#3680c6",
  warning: "#FAF0CA",
  warningContrast: "#AD9A4F",
  brown: "#D3D0CB",
  success: "#36C67E",
  lighter: "#e9e9e9",

  bgBody: "#F7F9F9"
};

export const images = {
  logoPrimary: image["logo-primary.png"],
  logoWarning: image["logo-warning.png"],
  facebook: image["facebook.png"],
  instagram: image["instagram.png"],
  whatsapp: image["whatsapp.png"],
  chevron: image["chevron.png"],
  chevronSecundary: image["chevron-secundary.png"],
  closeBrown: image["close-brown.png"],
  menuSecundary: image["menu-secundary.png"],
  mousePrimary: image["mouse-primary.png"],
  warningIcon: function(icon) {
    return image[icon + "-warning.png"];
  },
  bgQuemSomos: image["bg-quem-somos.png"],
  bgHome: image["bg-home.png"],
  bgRecursos: image["bg-recursos.png"],
  tie: image["tie.png"],
  rocket: image["rocket.png"],
  rule: image["rule.png"],
  close: image["close.png"],
  police: image["police.png"],
  cnh: image["cnh.png"],
  stepPrinter: image["step-printer.png"],
  stepMail: image["step-mail.png"],
  stepCalendar: image["step-calendar.png"],
  stepNotification: image["step-notification.png"],
  copy: image["copy.png"],
  copySuccess: image["copy-success.png"]
};
