import React, { Component, Fragment } from "react";
import { NavbarStyled, NavbarTop, Overlay, LinkStyled, A } from "./styles";
import { images, mobile } from "styles";
import Tooltip from "components/Tooltip";
import ReactTooltip from "react-tooltip";
import { colors } from "styles";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      animate: false,
      showTooltip: true,
      links: [
        {
          name: "Home",
          route: "/",
          url: false,
          active: false,
          disable: false
        },
        {
          name: "Recursos",
          route: "/recursos",
          url: false,
          active: false,
          disable: false
        },        
        {
          name: "Assistente Inteligente",
          route: "https://ai.adeusmultas.com/",
          url: true,
          active: false,
          disable: false
        },
        {
          name: "Blog",
          route: "/blog",
          url: true,
          active: false,
          disable: false
        },
        {
          name: "Perguntas Frequentes",
          route: "/faq",
          url: false,
          active: false,
          disable: false
        },
        {
          name: "Quem Somos",
          route: "/quemsomos",
          url: false,
          active: false,
          disable: false
        },
        {
          name: "Contato",
          route: "/contato",
          url: false,
          active: false,
          disable: false
        }
      ]
    };
  }

  componentDidMount(prev) {
    let links = this.state.links;
    for (let i = 0; i < links.length; i++) {
      if (links[i].route === this.props.page) {
        links[i].active = true;
      } else {
        links[i].active = false;
      }
    }

    this.setState({ links: links });
  }

  showSide = () => {
    this.setState({ animate: !this.state.animate });
  };

  render() {
    return (
      <Fragment>
        {mobile && (
          <NavbarTop pose={!this.state.animate ? "visible" : "hidden"}>
            <img
              alt="menu"
              onClick={() => this.showSide()}
              className="menu"
              src={images.menuSecundary}
            />
            <img alt="logo" className="logo-top" src={images.logoPrimary} />
          </NavbarTop>
        )}
        <NavbarStyled
          onClick={() => this.showSide()}
          pose={this.state.animate || !mobile ? "visible" : "hidden"}
        >
          {mobile && <img className="close" src={images.closeBrown} />}

          <div className="header">
            <img alt="logo" src={images.logoPrimary} />
          </div>
          <div className="body">
            {this.state.links.map((link, indice) => (
              <>
                {link.url ? (
                  <A href={!link.disable && link.route} target="_self">
                    {link.name}
                  </A>
                ) : (
                  <LinkStyled
                    key={indice}
                    style={{
                      color: link.disable
                        ? colors.lighter
                        : link.active
                        ? colors.primary
                        : colors.secundary
                    }}
                    active={link.active}
                    to={!link.disable && link.route}
                  >
                    {link.name}
                  </LinkStyled>
                )}
              </>
            ))}
          </div>

          <div className="footer">
            <a data-tip="React-tooltip" href="http://api.whatsapp.com/send?phone=5554984286630" target="_blank">
              <img alt="WhatsApp" src={images.whatsapp} />
            </a>

            <a href="https://fb.com/adeusmultas" target="_blank">
              <img alt="Fazcebook" src={images.facebook} />
            </a>
            <a href="https://instagram.com/adeusmultas" target="_blank">
              <img alt="Instagram" src={images.instagram} />
            </a>
          </div>
        </NavbarStyled>
        {mobile && (
          <Overlay
            onClick={() => this.showSide()}
            pose={this.state.animate ? "visible" : "hidden"}
          />
        )}
        <ReactTooltip
          className="tooltip"
          place="top"
          effect="solid"
          getContent={() => <Tooltip />}
          clickable
        />
      </Fragment>
    );
  }
}

export default Navbar;
