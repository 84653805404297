import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { colors, mobile } from "styles";
import { hex2rgba } from "utils";
import { Link } from "react-router-dom";

const animate = posed.div({
  visible: {
    x: 0,
    opacity: 1
  },
  hidden: {
    x: -260,
    opacity: 0
  }
});

const animateTop = posed.div({
  visible: {
    y: 0,
    opacity: 1
  },
  hidden: {
    y: -160,
    opacity: 0
  }
});

const animateOverlay = posed.div({
  visible: {
    opacity: 1,
    scale: 1
  },
  hidden: {
    opacity: 0,
    scale: 0
  }
});

export const NavbarStyled = styled(animate)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 60px);
  width: 200px;
  top: 0;
  background-color: ${colors.bgBody};
  -webkit-box-shadow: ${mobile
    ? `0 15px 20px ${hex2rgba(colors.black, 0.5)}`
    : "0 0 0"};
  -moz-box-shadow: ${mobile
    ? `0 15px 20px ${hex2rgba(colors.black, 0.5)}`
    : "0 0 0"};
  box-shadow: ${mobile
    ? `0 15px 20px ${hex2rgba(colors.black, 0.5)}`
    : "0 0 0"};
  padding: 30px !important;
  z-index: ${mobile ? 1000 : 0};
  //z-index: 100x;
  .header {
    padding-top: 30px;

    img {
      width: 145px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    flex: 1;
    font-weight: 600;
    font-size: 16px;
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
    padding-top: 30px;
    border-top: solid 1px ${hex2rgba(colors.brown, 0.35)};
    a {
      cursor: pointer;
    }
    img {
      max-width: 30px;
      cursor: pointer;
    }
  }

  .close {
    width: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .tooltip {
    padding: 10px;
    display: flex;
    width: 250px;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(60, 60, 60, 0.3);
    background-color: white;
    img {
      width: 20px;
    }
    span {
      color: ${props => (props.copied ? colors.success : colors.secundary)};
      font-size: 16px;
      margin-right: 10px;
      flex: 1;
    }
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
`;

export const NavbarTop = styled(animateTop)`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: calc(100% - 60px);
  background-color: ${colors.bgBody};
  -webkit-box-shadow: 0 3px 3px ${hex2rgba(colors.black, 0.2)};
  -moz-box-shadow: 0 3px 3px ${hex2rgba(colors.black, 0.2)};
  box-shadow: 0 3px 3px ${hex2rgba(colors.black, 0.2)};
  position: fixed;
  top: 0;
  z-index: 9999999;
  img.menu {
    width: 25px;
    position: absolute;
    left: 30px;
    cursor: pointer;
  }
  img.logo-top {
    width: 100px;
  }
`;

export const Overlay = styled(animateOverlay)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  //background-color: ${hex2rgba(colors.bgBody, 0.8)};
  background-color: transparent;
  z-index: 999;
  
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => (props.active ? colors.brown : colors.secundary)};
  margin-bottom: 30px;
  transition: 0.5s all;
  cursor: pointer;
  &:hover {
    color: ${props => (props.active ? colors.brown : colors.primary)};
    transition: 0.5s all;
    cursor: pointer;
  }
`;

export const A = styled.a`
  text-decoration: none;
  color: ${props => (props.active ? colors.brown : colors.secundary)};
  margin-bottom: 30px;
  transition: 0.5s all;
  cursor: pointer;
  &:hover {
    color: ${props => (props.active ? colors.brown : colors.primary)};
    transition: 0.5s all;
    cursor: pointer;
  }
`;
