import React, {Component} from 'react';
import {ToggleStyle, Body, Chevron} from './styles';
import{images} from "styles";

class Toggle extends Component {

    constructor( props ){
        super( props );
        this.state={
            isOpen:false,
        }
        // this.handleClick = this.handleClick.bind(this);
    }




    render() {
        const {item, index}=this.props
        return (
            <ToggleStyle>
                <div className='header' onClick={()=>this.setState({isOpen:!this.state.isOpen})}>
                    <h5>{index + '. ' + item.question}</h5>
                    <Chevron pose={this.state.isOpen?'visible':'hidden'} className='chevron' src={images.chevron}/>
                </div>
                <Body pose={this.state.isOpen?'visible':'hidden'}>
                <span>{item.answer}</span>
                </Body>
            </ToggleStyle>
        );
    }
}
export default Toggle;