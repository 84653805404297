import styled from "styled-components";
import { colors, mobile } from "styles";
// import { isMobile } from "react-device-detect";

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: ${colors.secundary};
    white-space: nowrap;
  }
`;

export const Line = styled.div`
  margin-top: 50px;
  width: 10px;
  flex: 1;
  border-radius: 5px;
  background-color: ${colors.secundary};
`;
