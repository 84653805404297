import styled from "styled-components";
import { colors, mobile } from "styles";
import { ContentStyled, RowStyled } from "components/Styled";
import { hex2rgba } from "utils";
// import { isMobile } from "react-device-detect";

export const Content = styled(ContentStyled)`
  flex: 1;
  margin-left: 0 !important;
  margin-top: 60px;
  padding-bottom: 60px;

  h3 {
    color: ${colors.dark};
  }
  p {
    margin-bottom: 5px;
  }
  span {
    max-width: 678px;
  }
`;

export const Row = styled(RowStyled)`
  justify-content: center;
  button {
    margin-bottom: 15px;
    &:first-child {
      margin-right: ${mobile ? "0" : "15px"};
    }
  }

  &.card {
    align-items: stretch;
    justify-content: center;
    flex-direction: ${mobile ? "column" : "row"};
    align-items: ${mobile ? "center" : "initial"};
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 60px 30px;
  flex: 1;
  display: flex;
  max-width: 410px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  span.body {
    text-align: center;
    justify-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  img {
    height: 60px;
    margin-bottom: 30px;
  }

  a.link-text {
    display: inherit !important;
    label {
      font-weight: 400 !important;
      font-size: 18px;
    }
  }
  &:nth-child(1) {
    box-shadow: ${mobile ? "0 -5px" : "-5px 5px"} 20px
      ${hex2rgba(colors.black, 0.3)};
    margin-bottom: ${mobile ? "30px" : 0};
    margin-right: ${mobile ? 0 : "30px"};
  }

  &:nth-child(2) {
    box-shadow: ${mobile ? "0 5px" : "5px 5px"} 20px
      ${hex2rgba(colors.black, 0.3)};
  }
  .blue {
    color: ${colors.secundary};
  }
  .btn-mobile {
    margin-top: 15px;
    width: 100%;
  }
`;

export const Select = styled.select`
  width: 100%;
  max-width: 800px;
  height: 38px;
  background-color: transparent;
  color: ${colors.secundary};
  border: solid 2px ${colors.secundary};

  font-size: ${mobile ? "14px" : "18px"};
  border-radius: 10px;
`;
