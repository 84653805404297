import React from "react";
import styled from "styled-components";
import { colors, mobile } from "styles";
import { Link } from "react-router-dom";
import { hex2rgba } from "utils";
import { ContentStyled, RowStyled } from "components/Styled";

export const FooterStyle = styled.div`
  position: relative;
  background-color: ${colors.primary};
  width: calc(100% + (${mobile ? 30 : 320}px));
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${mobile ? "-15px" : "-290px"};
  margin-right: ${mobile ? "-15px" : "-30px"};
  z-index: ${mobile ? 0 : 1000};
  margin-top: 30px;
  color: ${colors.warning};
  .logo {
    width: 250px;
  }
  .line {
    height: 250px;
    width: 2px;
    margin: 0 60px;
    background-color: ${colors.warning};
  }
  .social-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
    a {
      text-decoration: none;
      color: ${colors.warning};
      margin-left: 15px;
      font-size: 18px;
      font-weight: 300;
      
    }

    img {
      height: 24px;
    }
    img.email {
      height: 20px;
    }
  }

  .menu {
    margin-top: 60px;
    a {
      text-decoration: none;
      color: ${colors.warning};
      font-size: 16px;
      font-weight: 300;
      margin: 0 30px;
    }
  }

  .header {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .copyrights {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 5px;
      color: ${colors.warning};
    }
  }

  .develop {
    color: #fff;
  }
`;

export const FooterStyleMobile = styled(FooterStyle)`
  .line {
    height: 1px;
    width: 100%;

    margin: 30px 0;
    background-color: ${colors.warning};
  }
  .logo {
    width: 150px;
  }

  .social-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
    a {
      font-size: 14px;
    }

    img {
      height: 18px;
    }
    img.email {
      height: 16px;
    }
  }

  .copyrights {
    span {
      font-size: 14px;
    }
  }

  .develop {
    color: #fff;
  }
`;

export const Row = styled(RowStyled)`
  width: auto;
  align-items: center;
`;

export const Content = styled(ContentStyled)`
  align-items: center;
  z-index: 2;

  svg{
    max-width:1000px;
    margin-top:60px;
  }
  h2 {
    color: ${colors.dark};
    margin-bottom: 60px;
  }
  h1 {
    margin-top: 30px;
  }
  h3 {
    margin-bottom: 30px;
    margin-top: 60px;
  }
  span {
    text-align: center;
    width: 100%;
    max-width: 678px;
    &.info-text {
      font-size: ${mobile ? "15px" : "18px"};
      color: ${colors.secundary};

      .link-text{
        text-decoration:none;
        font-weight:400;
        border:solid 2px ${colors.secundary};
        border-radius:3px;
        color:${colors.secundary};
        transition:.2s all;
        padding:1px 4px;

        &:hover{
          color:${colors.primary};
          border-color:${colors.primary};
          transition:.2s all;
        }
      }
    }
  }

  .box-info {
    /* border: solid 4px ${colors.brown}; */
    /* border-radius: 10px; */
    padding: 30px;
    margin-bottom: 60px;
    p {
      &.desc {
        font-size: ${mobile ? "20px" : "30px"};
        font-weight: normal;
        color: ${colors.brown};
        text-align: center;
      }
      font-size: ${mobile ? "30px" : "45px"};
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      color: ${colors.brown};
      text-align: center;
    }
  }
`;