import React from "react";
import ContentLoader from "react-content-loader";
import { Content } from "./styles";
const Loader = () => (
  <ContentLoader
    height={300}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="100" y="0" rx="0" ry="0" width="400" height="13" />
    <rect x="0" y="30" rx="0" ry="0" width="500" height="13" />
    <rect x="0" y="60" rx="0" ry="0" width="500" height="13" />
    <rect x="0" y="90" rx="0" ry="0" width="400" height="13" />
    <rect x="100" y="150" rx="0" ry="0" width="400" height="13" />
    <rect x="0" y="180" rx="0" ry="0" width="500" height="13" />
    <rect x="0" y="210" rx="0" ry="0" width="500" height="13" />
    <rect x="0" y="240" rx="0" ry="0" width="500" height="13" />
    <rect x="0" y="270" rx="0" ry="0" width="400" height="13" />
  </ContentLoader>
);
export default Loader;
