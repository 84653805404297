import React, { Component } from "react";

import { Content, Line } from "./styles";

export default class Passos extends Component {
  render() {
    const { number } = this.props;
    return (
      <Content>
        <h2>{number}</h2>
        <Line />
      </Content>
    );
  }
}
