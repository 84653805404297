import React, { Component } from "react";

import { Content, Line } from "./styles";
import Button from "components/Button";

export default class Formulario extends Component {
  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };

  render() {
    const { type } = this.props;
    return (
      <Content>
        <h3>
          Cadastro
          {type === "1"
            ? " da Infração"
            : type === "2" && " para Recorrer da Suspenção/Cassação da CNH"}
        </h3>
        <span>
          Tenha em mãos os dados da{" "}
          {type === "1" ? " infração" : type === "2" && " sua CNH"} para
          preencher o formulário. Faça com atenção e não omita dados. Não se
          preocupe seus dados serão excluídos após a confecção do seu recurso.
        </span>

        <iframe
          className="iframe"
          title={
            type === "1"
              ? "Cadastro de Infração"
              : type === "2"
              ? "Cadastro de Cassação/Suspensão da CNH"
              : "Cadastro Geral"
          }
          src={
            type === "1"
              ? "https://docs.google.com/forms/d/e/1FAIpQLSdLgDpn2CCf6GiYkHpec_Y_NaJA99KH-2GGpt5puocZaoHbHQ/viewform?embedded=true"
              : type === "2" &&
                "https://docs.google.com/forms/d/e/1FAIpQLSfrTp58weMVGVvVV9sKn1S1XFZBKQM4Cfc4T5J4TM8GFmRklA/viewform?embedded=true"
          }
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Carregando…
        </iframe>
        <Button
          title="Clique aqui caso não consiga visualizar o formulário."
          bordered
          onClick={() =>
            this.action(
              "https://docs.google.com/forms/d/e/1FAIpQLSdLgDpn2CCf6GiYkHpec_Y_NaJA99KH-2GGpt5puocZaoHbHQ/viewform"
            )
          }
        />
      </Content>
    );
  }
}
