import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "pages/Home";
import Faq from "pages/Faq";
import QuemSomos from "pages/QuemSomos";
import Contato from "pages/Contato";
import Recursos from "pages/Recursos";
import AMTracking from "../pages/AMTracking";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/faq" component={Faq} />
      <Route path="/quemsomos" component={QuemSomos} />
      <Route path="/contato" component={Contato} />
      <Route path="/recursos" component={Recursos} />
  {/* <Route path="/monitoramento" component={AMTracking} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
