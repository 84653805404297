import React from 'react';
import{RowStyled} from "components/Styled";
import styled from 'styled-components';
import posed from 'react-pose';
import {colors} from 'styles';
import {hex2rgba} from 'utils';

const animate=posed.span({
   visible:{
       opacity:1
   },
    hidden:{
       opacity: 0
    }
});


export const Box=styled(RowStyled)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span{
    flex: 1;
    text-align: center;
  }
  img{
    width: 25px;
    cursor: pointer;
  }
  .left{
    transform-origin: center;
    transform: rotate(90deg);
  }
  .right{
    transform-origin: center;
    transform: rotate(-90deg);
  }
`;

export const Span=styled(animate)`

`;