import React, {Component, Fragment} from 'react';
import {Box, Span} from './styles';
import {images} from "styles";

class Caroussel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            depoimento: [
                {idx: 0, author: 'Allan – Olinda/PE', message: 'Tenho certeza que pelo seu bom atendimento e por ser atencioso com seus clientes, portas maiores se abrirão para você. Muito obrigado.'},
                {idx: 1, author: 'Eloan | Mauá/SP', message: 'Parabéns pelo ótimo atendimento e sobretudo pela honestidade.'},
                {idx: 2, author: 'Itamar | California/PR', message: 'Obrigado pela agilidade, confesso que fiquei meio temeroso em usar o site, mas estou satisfeito até agora com o atendimento.'},
                {idx: 3, author: 'Ramon | São Paulo/SP', message: 'Obrigado pela agilidade e qualidade no atendimento. Recomendarei vocês para todos que conheço e que precisarem de apoio para recurso.'},
                {idx: 4, author: 'Eduardo | São Paulo/SP', message: 'Dr. João, bom dia!!! Foi um Sucesso as multas que o Sr Recorreu para mim. Muito obrigado'},
            ],
            selected: {},
            animate:true,
        }
    }
    componentDidMount(prev) {
        this.setState({selected:this.state.depoimento[0]});
    }

    fwDepoimento=(idx)=>{
        this.setState({animate:false});
        let next=0;
        if(idx<(this.state.depoimento.length-1)){
            next=idx+1;
        }
        setTimeout(function () {
            this.setState({selected:this.state.depoimento[next], animate:true});
        }.bind(this), 350);

    }

    bkDepoimento=(idx)=>{
        this.setState({animate:false});
        let back=idx-1;
        if(idx<1){
            back=this.state.depoimento.length-1;
        }
        setTimeout(function () {
        this.setState({selected:this.state.depoimento[back], animate:true});
        }.bind(this), 350);
    }

    render() {
        const {selected, animate} = this.state;
        return (
            <Box>
                <img onClick={()=>this.bkDepoimento(selected.idx)} className='left' src={images.chevronSecundary}/>
                <Span pose={animate?'visible':'hidden'}>
                    <p>{selected.message}</p>
                    <b>{selected.author}</b>
                </Span>
                <img onClick={()=>this.fwDepoimento(selected.idx)} className='right' src={images.chevronSecundary}/>
            </Box>

        );
    }
}

export default Caroussel;