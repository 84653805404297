import React, { Component, Fragment } from "react";

import { Row, Line, ValoresStyled, Content } from "./styles";
import Button from "components/Button";
import { images } from "styles";

export default class Valores extends Component {
  renderInfracao = showModal => (
    <Row>
      <ValoresStyled>
        <label>
          Infração <br /> Leve e Média
        </label>
        <span className="cifrao">R$ </span>
        <h3>49,</h3>
        <span className="cents">90</span>
      </ValoresStyled>
      <Line />
      <ValoresStyled>
        <label>
          Infração <br /> Grave
        </label>
        <span className="cifrao">R$</span>
        <h3>69,</h3>
        <span className="cents">90</span>
      </ValoresStyled>
      <Line />
      <ValoresStyled>
        <label>
          Infração <br /> Gravíssima
          <br />
          <span className="obs">(sem multiplicador de valor)</span>
        </label>

        <span className="cifrao">R$</span>
        <h3>99,</h3>

        <span className="cents">90</span>
      </ValoresStyled>
      <Line />
      <ValoresStyled>
        <label>
          Infração <br /> Gravíssima
          <br />
          <span className="obs">(com multiplicador de valor)</span>
        </label>

        <Button
          title="Ver Valores"
          className="btn-valores" 
          bordered
          onClick={() => showModal()}
        />
      </ValoresStyled>
    </Row>
  );

  renderCassacao = () => (
    <Row>
      <ValoresStyled onlyValue>
        <span className="cifrao">R$ </span>
        <h3>199,</h3>
        <span className="cents">90</span>
      </ValoresStyled>
    </Row>
  );

  render() {
    const { showModal, type } = this.props;
    return (
      <Content>
        <img
          className="police-icon"
          type={type === "1" ? true : type === "2" && false}
          alt="Police"
          src={type === "1" ? images.police : type === "2" && images.cnh}
        />
        {type === "1" ? (
          <span className="police-desc">
            Recurso de
            <br />
            Infração de Trânsito
          </span>
        ) : (
          type === "2" && (
            <span className="police-desc">
              Suspenção/Cassação
              <br />
              da CNH
            </span>
          )
        )}

        {type === "1"
          ? this.renderInfracao(showModal)
          : type === "2" && this.renderCassacao()}
      </Content>
    );
  }
}
