import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { ContentStyled, RowStyled } from "components/Styled";
import { colors, mobile } from "styles";

export const Content = styled(ContentStyled)`
  align-items: flex-start;
  z-index: 2;

  h2 {
    color: ${colors.dark};
    margin-bottom: 60px;
  }
  h1 {
    margin-top: 30px;
  }
  h3 {
    margin-bottom: 15px;
    text-align: left;
  }

  span {
    max-width: 678px;
  }
  .box-content {
    min-height: calc(100vh - 60px);
  }

  input,
  textarea {
    margin-right: 15px;
    max-width: calc(100% - 15px);
    width: 100%;
    background-color: transparent;
    font-size: ${mobile ? "16px" : "20px"};
    padding: 10px 5px;
    border-radius: 10px;
    border: solid 2px ${colors.brown};
    &:focus {
      outline: none;
    }
  }
`;

export const Row = styled(RowStyled)`
  margin-top: 15px;
  max-width: 678px;
  &.only-mobile {
    flex-direction: ${mobile ? "column" : "row"};
    input {
      &:first-child {
        margin-bottom: ${mobile ? "15px" : "0"};
      }
    }
  }
`;
