import React, { Component, Fragment } from "react";
import Navbar from "navigation/Navbar/";
import { Content, Row } from "./styles";
import Toggle from "components/Toggle";
import Button from "components/Button";
import Footer from "components/Footer";
import OutrosContatos from "components/OutrosContatos";
import ReactGA from "react-ga";

class Contato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      subject: null,
      message: null
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-000000-01");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  action = (url, target) => {
    window.open(
      "mailto:contato@adeusmultas.com?subject=" +
        this.state.subject +
        "&body=Olá meu nome é: " +
        this.state.name +
        "%0D%0A" +
        this.state.message,
      "_self"
    );
  };

  render() {
    const { name, mail, subject, message } = this.state;
    return (
      <Fragment>
        <Navbar page="/contato" />
        <Content className="content">
          <div className="box-content">
            <h1>Contato</h1>
            <h2>
              Estamos prontos para atende-lo, nas redes sociais, por e-mail,
              ligação ou chat.
            </h2>
            <h3>Mensagem por email</h3>
            <span>
              Envie uma mensagem, entraremos em contato em no máximo 24h.
            </span>
            <Row>
              <input
                type="text"
                value={name}
                placeholder="Digite o seu Nome"
                onChange={e => this.setState({ name: e.target.value })}
              />
            </Row>
            <Row>
              <input
                type="text"
                value={subject}
                placeholder="Digite o assunto da mensagem"
                onChange={e => this.setState({ subject: e.target.value })}
              />
            </Row>

            <Row>
              <textarea
                value={message}
                rows="5"
                placeholder="Digite aqui mensagem que deseja enviar..."
                onChange={e => this.setState({ message: e.target.value })}
              />
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                title="Abrir e-mail e enviar"
                onClick={() => this.action()}
                style={{ marginRight: 15 }}
              />
            </Row>

            <OutrosContatos
              title="Outros Contatos"
              desc="Caso desejar, você pode nos contatar em nossos diversos canais."
            />
          </div>

          <Footer />
        </Content>
      </Fragment>
    );
  }
}

export default Contato;
