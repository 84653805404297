import styled from "styled-components";
import { colors, mobile } from "styles";
import { ContentStyled } from "components/Styled";
// import { isMobile } from "react-device-detect";

export const Content = styled(ContentStyled)`
  flex: 1;
  margin-left: 0 !important;
  margin-top: 60px;
  padding-bottom: 60px;
  h3 {
    color: ${colors.dark};
  }
  iframe {
    min-height: 560px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
`;
