import React, { Component, Fragment } from "react";

import { Content } from "./styles";

import Valores from "./Components/Valores";
import Passos from "./Components/Passos";
import Formulario from "./Components/Formulario";
import Pagamento from "./Components/Pagamento";
import ImpressaoEnvio from "./Components/ImpressaoEnvio";
import Final from "./Components/Final";

export default class Infracao extends Component {

  
  render() {
    const { showModalPreco, showModalPagSeguro, type } = this.props;
    return (
      <Fragment>
        <Valores type={type} showModal={showModalPreco} />
        <Content>
          <Passos number="1º Passo" />
          <Formulario type={type} />
        </Content>
        <Content>
          <Passos number="2º Passo" />
          <Pagamento type={type} showModal={showModalPagSeguro} />
        </Content>
        <Content>
          <Passos number="3º Passo" />
          <ImpressaoEnvio />
        </Content>
        <Content>
          <Passos number="Final" />
          <Final />
        </Content>
      </Fragment>
    );
  }
}
