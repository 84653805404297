import React, { Component, Fragment } from "react";
import { Row } from "./styles";
import Button from "components/Button";

class OutrosContatos extends Component {
  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };

  render() {
    const { title, desc, centered } = this.props;
    return (
      <Fragment>
        <h3 style={{ marginTop: 30 }}>{title}</h3>
        <span>{desc}</span>

        <h5>Redes Sociais</h5>
        <Row centered={centered}>
          <Button
            title="Facebook"
            onClick={() => this.action("https://fb.com/adeusmultas")}
            bordered
          />
          <span style={{ marginLeft: 10, marginRight: 10 }}>ou</span>
          <Button
            title="Instagram"
            onClick={() => this.action("https://instagram.com/adeusmultas")}
            bordered
          />
        </Row>
        <h5>Web</h5>
        <Row centered={centered}>
          <Button
            title="Blog"
            onClick={() => this.action("/blog", "_self")}
            bordered
          />
        </Row>
        <h5>Chat</h5>
        <Row centered={centered}>
          <Button
            title="Whatsapp"
            onClick={() => this.action("https://wa.me/5554984286630")}
            bordered
          />
        </Row>
      </Fragment>
    );
  }
}
export default OutrosContatos;
