import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { ContentStyled, RowStyled } from "components/Styled";
import { colors, images, mobile, tabled } from "styles";
import { isTablet, isMobile } from "react-device-detect";
import { hex2rgba } from "utils";

export const Content = styled(ContentStyled)`
  align-items: center;
  z-index: 2;

  svg{
    max-width:1000px;
    margin-top:60px;
  }
  h2 {
    color: ${colors.dark};
    margin-bottom: 60px;
  }
  h1 {
    margin-top: 30px;
  }
  h3 {
    margin-bottom: 30px;
    margin-top: 60px;
  }
  span {
    text-align: center;
    width: 100%;
    max-width: 678px;
    &.info-text {
      font-size: ${mobile ? "15px" : "18px"};
      color: ${colors.secundary};

      .link-text{
        text-decoration:none;
        font-weight:400;
        border:solid 2px ${colors.secundary};
        border-radius:3px;
        color:${colors.secundary};
        transition:.2s all;
        padding:0px 4px;

        &:hover{
          color:${colors.primary};
          border-color:${colors.primary};
          transition:.2s all;
        }
      }
    }
  }

  .box-info {
    /* border: solid 4px ${colors.brown}; */
    /* border-radius: 10px; */
    padding: 30px;
    margin-bottom: 60px;
    p {
      &.desc {
        font-size: ${mobile ? "20px" : "30px"};
        font-weight: normal;
        color: ${colors.brown};
        text-align: center;
      }
      font-size: ${mobile ? "30px" : "45px"};
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      color: ${colors.brown};
      text-align: center;
    }
  }
`;

export const Row = styled(RowStyled)`
  margin-bottom: 15px;
`;

export const Capa = styled.div`
  background: url(${images.bgRecursos});
  background-size: ${mobile || isTablet ? "auto 100vh" : "cover"};
  background-attachment: fixed;
  background-position: top left;
  background-position-x: ${!mobile || !isMobile ? "150px" : "auto"};
  background-repeat: no-repeat;

  width: calc(100% - ${mobile ? "30px" : "0px"});
  margin: ${mobile ? "-100px" : "-30px"} -30px 0 -30px;
  height: calc(100vh - 60px);
  max-height: ${mobile ? "500px" : "initial"};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2 {
    color: ${colors.white};
    text-shadow: 0 3px 6px ${hex2rgba(colors.black, 0.5)};
  }
  img {
    height: 45px;
    align-self: center;
    cursor: ns-resize;
    position: absolute;
    bottom: 30px;
    display: ${mobile ? "none" : "flex"};
  }
`;

export const Select = styled.select`
  width: 100%;
  max-width: 800px;
  height: ${mobile ? "40px" : "95px"};
  background-color: transparent;
  color: ${colors.primary};
  border: solid ${mobile ? "2px" : "5px"} ${colors.primary};
  font-size: ${mobile ? "20px" : "40px"};
  border-radius: 10px;
`;
