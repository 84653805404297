import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { FooterStyle, Row, FooterStyleMobile } from "./styles";
import { images, mobile } from "styles";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          name: "Home",
          route: "/",
          disable: false
        },
        {
          name: "Recursos",
          route: "/Recursos",
          disable: false
        },
        {
          name: "Monitoramento de Multas",
          route: "/monitoramento",
          disable: false
        },
        {
          name: "Blog",
          route: "/blog",
          disable: false
        },
        {
          name: "Perguntas Frequentes",
          route: "/faq",
          disable: false
        },
        {
          name: "Quem Somos",
          route: "/quemsomos",
          disable: false
        },
        {
          name: "Contato",
          route: "/contato",
          disable: false
        }
      ]
    };
  }

  render() {
    const {} = this.props;
    return (
      <Fragment>
        {!mobile && (
          <FooterStyle>
            <div className="header">
              <Row className="content">
                <img className="logo" src={images.logoWarning} />
                <div className="line" />
                <div className="social-box">
                  <div>
                    <img src={images.warningIcon("whatsapp")} />
                    <a href="https://wa.me/5554984286630" target="_blank">
                      (54) 9 8428-6630
                    </a>
                  </div>
                  <div>
                    <img className="email" src={images.warningIcon("email")} />
                    <a href="mailto:contato@adeusmultas.com">
                      contato@adeusmultas.com
                    </a>
                  </div>
                  <div>
                    <img src={images.warningIcon("facebook")} />
                    <a href="https://fb.com/adeusmultas" target="_blank">
                      fb.com/adeusmultas
                    </a>
                  </div>
                  <div>
                    <img src={images.warningIcon("instagram")} />
                    <a href="https://instagram.com/adeusmultas" target="_blank">
                      instagram.com/adeusmultas
                    </a>
                  </div>
                </div>
              </Row>
              <Row className="menu">
                {this.state.links.map((link, indice) => (
                  <Link key={indice} to={!link.disable && link.route}>
                    {link.name}
                  </Link>
                ))}
              </Row>
            </div>
            <div className="copyrights">
              <span>Todos os direitos reservados a Adeus Multas.</span>
              <span>
              <a
                  className="develop"
                  href="https://www.adeusmultas.com/externalfiles/contratoservico.pdf"
                  target="_blank"
                >
                  Contrato de Prestação de Serviços
                </a>
            </span>
            <span>
              <a
                  className="develop"
                  href="https://www.adeusmultas.com/externalfiles/politicadeprivacidade.pdf"
                  target="_blank"
                >
                  Política de Privacidade
                </a>
            </span>
              <span>
                Desenvolvido por{" "}
                <a
                  className="develop"
                  href="http://fb.com/cassiobat"
                  target="_blank"
                >
                  cassiobatista
                </a>{" "}
              </span>
            </div>
          </FooterStyle>
        )}
        {mobile && (
          <FooterStyleMobile>
            <div className="header">
              <img className="logo" src={images.logoWarning} />
              <div className="line" />
              <div className="social-box">
                <div>
                  <img src={images.warningIcon("whatsapp")} />
                  <a href="https://wa.me/5554984286630" target="_blank">
                    (54) 9 8428-6630
                  </a>
                </div>
                <div>
                  <img className="email" src={images.warningIcon("email")} />
                  <a href="mailto:contato@adeusmultas.com">
                    contato@adeusmultas.com
                  </a>
                </div>
                <div>
                  <img src={images.warningIcon("facebook")} />
                  <a href="https://fb.com/adeusmultas" target="_blank">
                    fb.com/adeusmultas
                  </a>
                </div>
                <div>
                  <img src={images.warningIcon("instagram")} />
                  <a href="https://instagram.com/adeusmultas" target="_blank">
                    instagram.com/adeusmultas
                  </a>
                </div>
              </div>
            </div>
            <div className="copyrights">
              <span>Todos os direitos reservados a Adeus Multas.</span>
            <span>
              <a
                  className="develop"
                  href="https://www.adeusmultas.com/externalfiles/contratoservico.pdf"
                  target="_blank"
                >
                  Contrato de Prestação de Serviços
                </a>
            </span>
            <span>
              <a
                  className="develop"
                  href="https://www.adeusmultas.com/externalfiles/politicadeprivacidade.pdf"
                  target="_blank"
                >
                  Política de Privacidade
                </a>
            </span>
              <span>
                Desenvolvido por{" "}
                <a
                  className="develop"
                  href="http://fb.com/cassiobat"
                  target="_blank"
                >
                  cassiobatista
                </a>{" "}
              </span>
            </div>
          </FooterStyleMobile>
        )}
      </Fragment>
    );
  }
}

export default Footer;
