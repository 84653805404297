import styled from "styled-components";
import { RowStyled } from "components/Styled";
import { colors, mobile } from "styles";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: ${mobile ? "100%" : "calc(100% + 30px)"};
  margin-left: ${mobile ? "0" : "-30px"};
  margin-bottom: 60px;
  justify-content: flex-start;
`;
