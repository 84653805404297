import React, { Component } from "react";
import { Container, ModalBox, Row, Valores, Line } from "./styles";
import { images } from "styles";
import Button from "components/Button";

export default class ModalPrecos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  render() {
    const { show, action } = this.props;
    return (
      <Container pose={show ? "visible" : "hidden"}>
        <ModalBox>
          <Row>
            <h3>Infrações com Multiplicador de Valor</h3>
            <img
              onClick={() => action()}
              className="ico-close"
              alt="close"
              src={images.close}
            />
          </Row>
          <Row className="values">
            <Valores>
              <label>
                Multa de <br /> R$880,41
                <br /> <span className="valor-recurso">Valor do Recurso:</span>
              </label>
              <span className="cifrao">R$ </span>
              <h3>134,</h3>
              <span className="cents">90</span>
            </Valores>
            <Line />
            <Valores>
              <label>
                Multa de <br /> R$1467,35
                <br /> <span className="valor-recurso">Valor do Recurso:</span>
              </label>
              <span className="cifrao">R$ </span>
              <h3>154,</h3>
              <span className="cents">90</span>
            </Valores>
            <Line />
            <Valores>
              <label>
                Multa de <br /> R$2934,70 ou +
                <br /> <span className="valor-recurso">Valor do Recurso:</span>
              </label>
              <span className="cifrao">R$ </span>
              <h3>184,</h3>
              <span className="cents">90</span>
            </Valores>
          </Row>
        </ModalBox>
      </Container>
    );
  }
}
