import styled from "styled-components";
import { colors } from "styles";
import { hex2rgba } from "utils";
import { Link } from "react-router-dom";

export const ButtonStyle = styled.button`
  background-color: ${colors.warning};
  border-radius: 5px;
  transition: 0.5s all;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-shrink: 0;
  -webkit-box-shadow: 0 3px 10px ${colors.black};
  -moz-box-shadow: 0 3px 10px ${colors.black};
  box-shadow: 0 2px 5px ${hex2rgba(colors.black, 0.5)};
  border: none;
  cursor: pointer;
  label {
    transition: 0.5s all;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    cursor: pointer;
  }

  &:hover {
    transition: 0.5s all;
    border-color: ${colors.primary};
    label {
      transition: 0.5s all;
      color: ${colors.primary};
    }
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonBordered = styled.button`
  background-color: transparent;
  border: solid 2px ${colors.secundary};
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-shrink: 0;
  transition: 0.5s all;
  cursor: pointer;
  label {
    cursor: pointer;
    transition: 0.5s all;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
  }

  &:hover {
    transition: 0.5s all;
    border-color: ${colors.primary};
    label {
      transition: 0.5s all;
      color: ${colors.primary};
    }
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonStyleTo = styled(Link)`
  background-color: ${colors.warning};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-decoration: none;
  padding: 10px;
  transition: 0.5s all;
  -webkit-box-shadow: 0 3px 10px ${colors.black};
  -moz-box-shadow: 0 3px 10px ${colors.black};
  box-shadow: 0 2px 5px ${hex2rgba(colors.black, 0.5)};
  border: none;
  cursor: pointer;
  a {
    justify-content: center;
  }
  label {
    transition: 0.5s all;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    cursor: pointer;
  }
  &:hover {
    transition: 0.5s all;
    border-color: ${colors.primary};
    label {
      transition: 0.5s all;
      color: ${colors.primary};
    }
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonBorderedTo = styled(Link)`
  background-color: transparent;
  border: solid 2px ${colors.secundary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-decoration: none;
  padding: 10px;
  transition: 0.5s all;
  a {
    justify-content: center;
  }
  cursor: pointer;
  label {
    cursor: pointer;
    transition: 0.5s all;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
  }

  &:hover {
    transition: 0.5s all;
    border-color: ${colors.primary};
    label {
      transition: 0.5s all;
      color: ${colors.primary};
    }
  }

  &:focus {
    outline: none;
  }
`;
