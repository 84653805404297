import React, { Component } from "react";
import { Container } from "./styles";

export default class Final extends Component {
  render() {
    return (
      <Container>
        <h3>Aguardar Resposta</h3>
        <span>
          Após o recebimento da Autoridade julgadora, pode levar até 120 dias
          para que o caso seja julgado.
        </span>
      </Container>
    );
  }
}
