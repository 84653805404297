import styled from "styled-components";
import posed from "react-pose";
import { RowStyled } from "components/Styled";
import { mobile, colors } from "styles";
import { hex2rgba } from "utils";

const animate = posed.div({
  visible: {
    opacity: 1,
    scale: 1
  },
  hidden: {
    opacity: 0,
    scale: 0
  }
});

export const ModalBox = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 700px;
  margin: 30px;
  border-radius: 10px;
  box-shadow: 0 20px 20px ${hex2rgba(colors.black, 0.2)};
  h3 {
    font-size: ${mobile ? "30px" : "44px"};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    color: ${colors.secundary};
  }
  img.ico-close {
    width: 35px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }

  .body {
    max-height: calc(100vh - 230px);
    padding-top: 15px;
    flex-shrink: 0;
    align-items: flex-start;
    overflow: auto;
  }

  span {
    font-size: 18px;
    font-weight: 300;
    margin-top: 0;
    line-height: 1.5;
    margin-bottom: 15px;
    color: ${colors.dark};
  }
`;

export const Container = styled(animate)`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${hex2rgba(colors.bgBody, 0.7)};
  z-index: 99999;
`;

export const Row = styled(RowStyled)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
