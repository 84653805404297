import React, { Component, Fragment } from "react";

import { Container, Box } from "./styles";
import { images } from "styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "54984286630",
      copied: false
    };
  }
  render() {
    const { value, copied } = this.state;

    return (
      <Box copied={copied}>
        <span> WhatsApp: (54) 9 8428-6630 </span>
        <CopyToClipboard
          text={value}
          onCopy={() => this.setState({ copied: true })}
        >
          <img alt="Copiar" src={copied ? images.copySuccess : images.copy} />
        </CopyToClipboard>
      </Box>
    );
  }
}
