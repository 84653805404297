import React from 'react';
import{RowStyled} from "components/Styled";
import styled from 'styled-components';
import {colors} from 'styles';
import {hex2rgba} from 'utils';




export const Row=styled(RowStyled)`
justify-content: ${props=>props.centered?'center':'flex-start'};
  margin-bottom: 15px;
`;