import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import { ContentStyled, RowStyled } from "components/Styled";
import { colors, images, mobile, tabled } from "styles";
import { isTablet, isMobile } from "react-device-detect";
import { hex2rgba } from "utils";

export const Content = styled(ContentStyled)`
  align-items: center;
  z-index: 2;

  h2 {
    color: ${colors.dark};
    margin-bottom: 60px;
  }
  h1 {
    margin-top: 0;
  }
  h3 {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }

  span {
    text-align: center;
    max-width: 678px;
  }

  .box-info {
    border: solid 4px ${colors.brown};
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 60px;
    p {
      &.desc {
        font-size: ${mobile ? "20px" : "30px"};
        font-weight: normal;
        color: ${colors.brown};
        text-align: center;
      }
      font-size: ${mobile ? "30px" : "45px"};
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      color: ${colors.brown};
      text-align: center;
    }
  }
`;

export const Row = styled(RowStyled)`
  margin-bottom: 15px;
  &.card {
    align-items: stretch;
    justify-content: center;
    flex-direction: ${mobile ? "column" : "row"};
    align-items: ${mobile ? "center" : "initial"};
  }
`;

export const Capa = styled.div`
  background: url(${images.bgHome});
  background-size: ${mobile || isTablet ? "auto 100vh" : "cover"};
  background-attachment: fixed;
  background-position: top left;
  background-position-x: ${!mobile || !isMobile ? "150px" : "auto"};
  background-repeat: no-repeat;

  width: calc(100% - ${mobile ? "30px" : "0px"});
  margin: ${mobile ? "-100px" : "-30px"} -30px 0 -30px;
  min-height: calc(100vh - 60px);
  max-height: ${mobile ? "500px" : "initial"};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2 {
    color: ${colors.white};
    text-shadow: 0 3px 6px ${hex2rgba(colors.black, 0.5)};
    margin-bottom: 30px;
  }
  img {
    height: 45px;
    align-self: center;
    cursor: ns-resize;
    position: absolute;
    bottom: 30px;
    display: ${mobile ? "none" : "flex"};
  }

  .desc-intro-text {
    font-weight: light;
    font-size: ${mobile ? "26px" : "36px"};
    color: ${colors.primary};
    margin-top: ${mobile ? "100px" : "0"};
    margin-bottom: 5px;
    p {
      margin: 0 !important;
    }
    text-align: left;
  }

  .or-text {
    color: ${colors.white};
    text-shadow: 0 3px 6px ${hex2rgba(colors.black, 0.5)};
    font-size: 20px;
    font-weight: bold;
    margin: ${mobile ? "10px auto" : "auto 15px"};
  }

  .capa-btn-group {
    flex-direction: ${mobile ? "column" : "row"};
    align-items: center;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 60px 30px;
  flex: 1;
  display: flex;
  max-width: 210px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    flex: 1;
  }
  img {
    height: 60px;
    margin-bottom: 30px;
  }
  &:nth-child(1) {
    box-shadow: ${mobile ? "0 -5px" : "-10px 10px"} 20px
      ${hex2rgba(colors.black, 0.3)};
    margin-bottom: ${mobile ? "30px" : 0};
  }
  &:nth-child(2) {
    box-shadow: ${mobile ? "0 0" : "0 10px"} 20px ${hex2rgba(colors.black, 0.3)};
    margin: 0 ${mobile ? "0" : "30px"};
    margin-bottom: ${mobile ? "30px" : 0};
  }
  &:nth-child(3) {
    box-shadow: ${mobile ? "0 5px" : "10px 10px"} 20px
      ${hex2rgba(colors.black, 0.3)};
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: ${mobile ? "15px 15px 0px 15px" : "30px 30px 15px 30px"};
  &.first {
    flex: 1;
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      color: ${colors.white};
    }
    margin-right: ${mobile ? "10px" : "30px"};
    background-color: ${colors.primary};
    box-shadow: ${mobile ? "0 5px 10px" : "0 10px 20px"};
      ${hex2rgba(colors.black, 0.3)};
  }

  &.second {
    h3 {
      margin-top: 0;
      margin-bottom: 15px;
      color: ${colors.brown};
      opacity: 0.6;
    }
    background-color: ${colors.white};
    box-shadow: ${mobile ? "-5px 5px 10px" : "-10px 10px 20px"};
      ${hex2rgba(colors.black, 0.3)};
  }

  .btn-step {
    width: ${mobile ? "calc(100% - 20px)" : "150px"};
  }
`;

export const Line = styled.div`
  width: 100%;
  min-width: ${mobile ? "50px" : "200px"};
  height: 10px;
  margin-bottom: 15px;
  background-color: ${colors.brown};
  opacity: 0.6;
  &.first-line {
    margin-top: 15px;
    margin-left: ${mobile ? "15px" : "30px"};
    width: calc(100% - 30px);
  }
  &:first-child {
    margin-top: 0 !important;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: ${mobile ? "40px" : "95px"};
  background-color: transparent;
  color: ${colors.white};
  border: solid ${mobile ? "2px" : "5px"} ${colors.white};
  font-size: ${mobile ? "20px" : "40px"};
  border-radius: 10px;
`;
