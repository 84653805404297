import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import {colors} from 'styles'


const animate=posed.div({
    hidden: {
        height: 0,
        opacity: 0,
        paddingTop:0,
        paddingBottom:0,
        transition:{
            height:{
                delay:200
            }
        }
    },
    visible: {
        height: 'auto',
        opacity: 1,
        delayChildren: 500,
        staggerChildren: 100,
        paddingTop:20,
        paddingBottom:20,
    },
    initialPose: 'hidden'
});

export const Chevron = posed.img({
    visible: {
        rotate: -180,
        transformOrigin: 'center'
    },
    hidden: {
        rotate: 0,
        transformOrigin: 'center'
    },
    initialPose: 'hidden'
});

export const ToggleStyle=styled.div`
  margin-bottom: 15px;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 100;
  .header{
    cursor: pointer;
    border-bottom: solid 2px ${colors.brown};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    margin-bottom: 10px;
    
    h5{
      margin-bottom: 0;
    }
    
    .chevron{
      width: 17px;
    }
  }
`;

export const Body=styled(animate)`
`;