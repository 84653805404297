import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import {ContentStyled, RowStyled} from "components/Styled";
import {colors, images, mobile, tabled} from 'styles';
import {isTablet, isMobile} from "react-device-detect";
import {hex2rgba} from 'utils';



export const Content=styled(ContentStyled)`
  align-items: center;
  z-index: 2;

  h2{
    color:${colors.dark};
    margin-bottom: 60px;
  }
  h1{
    margin-top: 30px;
  }
  h3{
    margin-bottom: 30px;
    margin-top: 60px;
  }
  h4{
    color:${colors.dark};
    margin-top: -10px;
  }
  span{
    text-align: center;
    max-width: 678px;
  }
  iframe {
    min-height: 560px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 60px 30px;
  flex: 1;
  display: flex;
  max-width: 510px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    flex: 1;
    size: 10;
  }
  img {
    height: 60px;
    margin-bottom: 30px;
  }
  &:nth-child(1) {
    box-shadow: ${mobile ? "0 -5px" : "-10px 10px"} 20px
      ${hex2rgba(colors.black, 0.3)};
    margin-bottom: ${mobile ? "30px" : 0};
  }
  &:nth-child(2) {
    box-shadow: ${mobile ? "0 0" : "0 10px"} 20px ${hex2rgba(colors.black, 0.3)};
    margin: 0 ${mobile ? "0" : "30px"};
    margin-bottom: ${mobile ? "30px" : 0};
  }
  &:nth-child(3) {
    box-shadow: ${mobile ? "0 5px" : "10px 10px"} 20px
      ${hex2rgba(colors.black, 0.3)};
  }
`;

export const Line = styled.div`
  width: ${mobile ? "200px" : "4px"};
  border-radius: 2px;
  height: ${mobile ? "4px" : "80px"};
  background-color: ${colors.brown};
  margin: ${mobile ? "30px auto" : "auto 30px"};
`;
export const ValoresStyled = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  height: ${props => (props.onlyValue ? "auto" : "160px:")};
  position: relative;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    text-align: center;
    flex: 1;
  }
  h3 {
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .cifrao,
  .cents {
    position: absolute;
    bottom: 0;
    font-size: 26px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents {
    margin-left: ${props => (props.onlyValue ? "130px" : "85px")};
    margin-bottom: 10px;
  }
  .cents2 {
    position: absolute;
    bottom: 0;
    font-size: 26px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents2 {
    margin-left: ${props => (props.onlyValue ? "180px" : (mobile ? "70px" : "115px"))};
    margin-top: 0px;
    margin-bottom: ${mobile ? "-20px" : "-20px"};
  }
  .cifrao {
    margin-left: ${props => (props.onlyValue ? "-65px" : "-55px")};
    margin-bottom: 53px;
  }

  .obs {
    margin-top: 10px;
    font-size: 16px;
    line-height: 0.4;
    white-space: nowrap;
  }

  .btn-valores {
    margin-bottom: 18px;
  }
`;

export const ValoresStyled2 = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  height: ${props => (props.onlyValue ? "auto" : "160px:")};
  position: relative;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    text-align: center;
    flex: 1;
  }
  h3 {
    font-size: 60px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .cifrao,
  .cents {
    position: relative;
    font-size: 26px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents {
    margin-left: ${props => (props.onlyValue ? "185px" : "120px")};
    margin-bottom: 00px;
    margin-top: -40px;
  }
  .cents2 {
    position: relative;
    font-size: 26px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents2 {
    margin-left: ${props => (props.onlyValue ? "180px" : (mobile ? "70px" : "115px"))};
    margin-top: -40px;
    margin-bottom: 10px;
    margin-bottom: ${mobile ? "-20px" : "-20px"};
  }
  .cifrao {
    position: relative;
    margin-left: ${props => (props.onlyValue ? "-65px" : "-55px")};
    margin-top: 10px;
    margin-bottom: -30px;
  }

  .obs {
    margin-top: 10px;
    font-size: 16px;
    line-height: 0.4;
    white-space: nowrap;
  }

  .btn-valores {
    margin-bottom: 18px;
  }
`;

export const RowPrecos = styled(RowStyled)`
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 00px;
  flex-direction: ${mobile ? "column" : "row"};
  align-items: ${mobile ? "center" : "initial"};

  .btn-pagaMonitoramento {
    justify-content: center;
    position: relative;
    margin-top: 50px;
    margin-left: -20px;
    margin-right: 40px;
  }
`;

export const RowButtons = styled(RowStyled)`
  max-width: 500px;
  margin-top: 30px;
  margin-bottom: 00px;
  flex-direction: ${mobile ? "column" : "row"};
  align-items: ${mobile ? "center" : "initial"};

  .btn-pagaMonitoramento {
    
    position: relative;
    margin-top: 0px;
    margin-left: -0;
    margin-right: 0px;
  }
`;

export const RowPrecos2 = styled(RowStyled)`
  max-width: 800px;
  margin-top: -30px;
  margin-bottom: 65px;
  flex-direction: ${mobile ? "column" : "row"};
  align-items: ${mobile ? "center" : "initial"};
`;

export const Row=styled(RowStyled)`

  margin-bottom: 15px;
  &.card {
    align-items: stretch;
    justify-content: center;
    flex-direction: ${mobile ? "column" : "row"};
    align-items: ${mobile ? "center" : "initial"};
  }
`;

export const Quadro=styled(RowStyled)`

  span{
    text-align: center;
    max-width: 1078px;
    font-size: 20px;
  }

`;

export const Capa=styled.div`
  background: url(${images.bgQuemSomos});
  background-size: ${(mobile|| isTablet)?'auto 100vh':'cover'} ;
  background-attachment: fixed;
  background-position: top left;
  background-position-x: ${(!mobile || !isMobile) ?'150px':'auto'};
  background-repeat: no-repeat;
  
  width: calc(100% -  ${mobile?'30px':'0px'});
  margin: ${mobile?'-100px':'-30px'} -30px 0 -30px;
  height: calc(100vh - 60px);
  max-height: ${mobile?'500px':'initial'};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  
  h2{
    color:${colors.white};
    text-shadow: 0 3px 6px ${hex2rgba(colors.black,0.5)};
  }
  img{
    height: 45px;
    align-self: center;
    cursor: ns-resize;
    position: absolute;
    bottom: 30px;
    display: ${mobile?'none':'flex'};
  }
`;


export const ContentMonitoramento = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: ${mobile ? "100%" : "calc(100% + 30px)"};
  margin-left: ${mobile ? "0" : "-30px"};
  margin-bottom: 60px;
  justify-content: flex-start;
`;


export const ContentPassos = styled(ContentStyled)`
  flex: 1;
  margin-left: 0 !important;
  margin-top: 60px;
  padding-bottom: 60px;
  h3 {
    color: ${colors.secundary};
  }
  iframe {
    min-height: 560px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .texto1 {
    color:${colors.dark};
    font-size: 30px;
    font-weight: bold;
  }
  span {
    text-align: center;
    width: 100%;
    max-width: 678px;
    &.info-text {
      font-size: ${mobile ? "15px" : "18px"};
      color: ${colors.secundary};

      .link-text{
        text-decoration:none;
        font-weight:400;
        border:solid 2px ${colors.secundary};
        border-radius:3px;
        color:${colors.secundary};
        transition:.2s all;
        padding:0px 4px;

        &:hover{
          color:${colors.primary};
          border-color:${colors.primary};
          transition:.2s all;
        }
      }
    }
  }
`;
