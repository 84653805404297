import React, { Component, Fragment } from "react";
import Navbar from "navigation/Navbar/";
import { Content, Row, Capa } from "./styles";
import { images, screenHeight } from "styles";
import Toggle from "components/Toggle";
import Button from "components/Button";
import Footer from "components/Footer";
import OutrosContatos from "components/OutrosContatos";
import Caroussel from "components/Caroussel";
import ReactGA from "react-ga";

class QuemSomos extends Component {
  state = {
    list: [
      {
        title: "Equipe",
        desc:
          "Profissionais com vasta experiência teórica e prática no Direito de Trânsito. Somos especialistas em identificar irregularidades em infrações, buscando a conversão da multa em advertência ou a própria anulação da infração."
      },
      {
        title: "Missão",
        desc:
          "Compreendemos que você, cliente, possui pressa e se sente tenso após o recebimento de uma Notificação de Infração de Trânsito.\n" +
          "Nossa missão principal é trazer tranquilidade a você. Obtemos isso através de um serviço rápido e uma alta taxa de sucesso em nossos recursos."
      },
      {
        title: "Valores",
        desc:
          "Nossa atuação profissional é sempre pautada em valores, como transparência e integridade. Se consideramos que um recurso de infração para o seu caso não é viável, por não possuir muitas chances de sucesso, seremos bem claros quanto a isso. Em hipótese alguma mentiremos para os clientes sobre as chances dele com o recurso."
      }
    ]
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-000000-01");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };
  scrollTop = () => {
    window.scroll(0, screenHeight);
  };

  render() {
    return (
      <Fragment>
        <Navbar page="/quemsomos" />
        <Content className="content">
          <Capa>
            <h1>Quem Somos</h1>
            <h2>Pequenos, mas justos. Nobres, mas humildes.</h2>
            <img onClick={() => this.scrollTop()} src={images.mousePrimary} />
          </Capa>
          {this.state.list.map(lista => (
            <Fragment>
              <h3>{lista.title}</h3>
              <span>{lista.desc}</span>
            </Fragment>
          ))}

          <h3>O que dizem nossos clientes?</h3>

          <Caroussel />

          <OutrosContatos
            centered
            title="Saiba Mais"
            desc="Quer saber mais sobre nós e nosso trabalho? Acesse e nos siga em nosso canais."
          />
          <Footer />
        </Content>
      </Fragment>
    );
  }
}

export default QuemSomos;
