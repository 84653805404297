import styled from "styled-components";
import posed from "react-pose";
import { RowStyled } from "components/Styled";
import { mobile, colors } from "styles";
import { hex2rgba } from "utils";

const animate = posed.div({
  visible: {
    opacity: 1,
    scale: 1
  },
  hidden: {
    opacity: 0,
    scale: 0
  }
});

export const ModalBox = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 700px;
  margin: 30px;
  border-radius: 10px;
  box-shadow: 0 20px 20px ${hex2rgba(colors.black, 0.2)};
  h3 {
    font-size: ${mobile ? "24px" : "34px"};
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    color: ${colors.secundary};
  }
  img.ico-close {
    width: 35px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }

  span {
    font-size: 18px;
    font-weight: 300;
    margin-top: 0;
    line-height: 1.5;
    margin-bottom: 15px;
    color: ${colors.dark};
  }
`;

export const Container = styled(animate)`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${hex2rgba(colors.bgBody, 0.7)};
  z-index: 99999;
`;

export const Row = styled(RowStyled)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &.values {
    flex-direction: ${mobile ? "column" : "row"};
    max-height: calc(100vh - 190px);
    overflow: ${mobile ? "auto" : "none"};
    align-items: ${mobile ? "center" : "initial"};
    /* max-width: 800px; */
    margin-top: ${mobile ? 0 : "30px"};
    margin-bottom: ${mobile ? 0 : "60px"};
  }
`;

export const Line = styled.div`
  width: ${mobile ? "200px" : "4px"};
  border-radius: 2px;
  height: ${mobile ? "4px" : "80px"};
  background-color: ${colors.brown};
  margin: ${mobile ? "30px auto" : "auto 30px"};
  flex-shrink: 0;
`;
export const Valores = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 150px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  label {
    font-size: 20px;
    font-weight: 600;
    color: ${colors.secundary};
    text-align: center;
    flex: 1;

    .valor-recurso {
      font-weight: bold;
      font-size: 14px;
    }
  }
  h3 {
    font-size: 60px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .cifrao,
  .cents {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    color: ${colors.secundary};
    font-weight: bold;
  }
  .cents {
    margin-left: 125px;
  }
  .cifrao {
    margin-left: -65px;
    margin-bottom: 53px;
  }

  .obs {
    font-size: 12px;
    line-height: 0.3;
    white-space: nowrap;
  }

  .btn-valores {
    margin-bottom: 18px;
  }
`;
