import React, { Component } from "react";
import { Container, Row, Column } from "./styles";
import { images } from "styles";

export default class ImpressaoEnvio extends Component {
  render() {
    return (
      <Container>
        <h3>Impressão e Envio</h3>
        <span>
          Após receber o recurso em seu e-mail (cerca de 24 horas úteis após o
          pagamento), você deverá realizar os seguintes passos:
        </span>
        <Row>
          <Column className="left">
            <img alt="Impressora" src={images.stepPrinter} />
            <div className="line" />
          </Column>
          <Column>
            <h4>Imprimir</h4>
            <span>Imprimir o arquivo PDF enviado para o seu e-mail.</span>
          </Column>
        </Row>
        <Row>
          <Column className="left">
            <img
              className="height"
              alt="Correspondência"
              src={images.stepMail}
            />
            <div className="line" />
          </Column>
          <Column>
            <h4>Correspondência</h4>
            <span>
              Coloque o documento em um envelope (pequeno ou grande), e escreva
              o endereço de envio que consta na notificação da Infração de
              trânsito.
            </span>
          </Column>
        </Row>
        <Row>
          <Column className="left">
            <img alt="Calendário" src={images.stepCalendar} />
            <div className="line" />
          </Column>
          <Column>
            <h4>Prazo de Envio</h4>
            <span>
              Antes de encaminhar sua correspondência verifique o prazo que
              consta na notificação de infração para que o recurso seja enviado.
              Caso o prazo esteja próximo, solicite um envio mais rápido.
            </span>
          </Column>
        </Row>
        <Row>
          <Column className="left">
            <img alt="Notificação" src={images.stepNotification} />
            <div className="line" />
          </Column>
          <Column>
            <h4>Aviso de Recebimento</h4>
            <span>
              Registre a correspondência como AR (Aviso de Recebimento). Na
              declaração de conteúdo do seu AR, escreva “Defesa Prévia/Recurso”
              e o número do Auto de Infração de Trânsito (AIT).
            </span>
          </Column>
        </Row>
        <Column className="alert">
          <Row className="item">
            <span>
              Se você residir na mesma cidade onde fica a Autoridade Julgadora,
              simplesmente entregue na repartição indicada na Notificação,
              requerendo um protocolo de recebimento com data e assinatura do
              responsável.
            </span>
          </Row>
          <Row className="item">
            <span>
              {" "}
              Não é necessário reconhecer a assinatura em cartório, desde que a
              assinatura seja a mesma do documento de identificação, que
              sugerimos sempre ser a sua Carteira Nacional de Habilitação, a não
              ser que ela esteja vencida, caso em que deverá enviar sua Carteira
              de Identidade.
            </span>
          </Row>
          <Row className="item">
            <span>
              O Aviso de Recebimento dos Correios é uma ferramenta poderosa para
              que você consiga comprovar o envio e recebimento do seu recurso
              pela Autoridade Julgadora. Só assim você poderá, em caso de
              extravio do recurso, comprovar sua entrega.
            </span>
          </Row>
        </Column>
      </Container>
    );
  }
}
