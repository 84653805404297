import styled from "styled-components";
import { ContentStyled, RowStyled } from "components/Styled";
import { colors, mobile } from "styles";

export const Container = styled(ContentStyled)`
  flex: 1;
  margin-left: 0 !important;
  margin-top: 60px;
  padding-bottom: 60px;

  h3 {
    color: ${colors.dark};
  }
  span {
    max-width: 678px;
    margin-bottom: 30px;
  }
`;

export const Row = styled(RowStyled)`
  align-items: stretch;
  justify-content: center;
  width: ${mobile ? "260px" : "500px"};
  margin-bottom: 15px;
`;
export const Column = styled.div`
display:flex;
flex-direction:Column;
align-items:flex-start;
justify-content:center;


span{
  text-align:left;
  margin-bottom:15px;
  margin-top:15px;
}
h4{
font-size: ${mobile ? "16px" : "24px"};
height:40px;
display:flex;
flex-direction:row;
align-items:center;
justify-content:flex-start;
}
&.left{
  width:40px;
  margin-right:30px;
  align-items:center;
}
img{
  &.height{
    width:45px;
  }
  width:40px;
  margin-bottom:15px;
}
.line{
  width:4px;
  border-radius:2px;
  background-color:${colors.secundary}
  flex:1;
}

&.alert {
    flex-direction: column;
    background-color: ${colors.warning};
    width: calc(100% - 30px);
    border-radius: 10px;
    padding: 15px;
    position:relative;
    border: solid 4px ${colors.warningContrast};
    margin-top:30px;
    .item {
      position:relative;
     width:100%;
     margin-bottom:0;
      span{
        color: ${colors.warningContrast};
      font-size:${mobile ? "10px" : "14px"}
      max-width: 100%;
      font-weight:400;
      margin-left:15px;
      }
      span:before {
      content: "\00B7";
      position:absolute;
      font-size:55px;
      top:50%;
      margin-top:-35px;
      margin-left:-20px;

    }
      
    }
  
  }

`;
