import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import {ContentStyled, RowStyled} from "components/Styled";
import {colors, images, mobile, tabled} from 'styles';
import {isTablet, isMobile} from "react-device-detect";
import {hex2rgba} from 'utils';



export const Content=styled(ContentStyled)`
  align-items: center;
  z-index: 2;

  h2{
    color:${colors.dark};
    margin-bottom: 60px;
  }
  h1{
    margin-top: 30px;
  }
  h3{
    margin-bottom: 30px;
    margin-top: 60px;
  }
 
  span{
    text-align: center;
    max-width: 678px;
  }
  
`;


export const Row=styled(RowStyled)`

  margin-bottom: 15px;
`;

export const Capa=styled.div`
  background: url(${images.bgQuemSomos});
  background-size: ${(mobile|| isTablet)?'auto 100vh':'cover'} ;
  background-attachment: fixed;
  background-position: top left;
  background-position-x: ${(!mobile || !isMobile) ?'150px':'auto'};
  background-repeat: no-repeat;
  
  width: calc(100% -  ${mobile?'30px':'0px'});
  margin: ${mobile?'-100px':'-30px'} -30px 0 -30px;
  height: calc(100vh - 60px);
  max-height: ${mobile?'500px':'initial'};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  
  h2{
    color:${colors.white};
    text-shadow: 0 3px 6px ${hex2rgba(colors.black,0.5)};
  }
  img{
    height: 45px;
    align-self: center;
    cursor: ns-resize;
    position: absolute;
    bottom: 30px;
    display: ${mobile?'none':'flex'};
  }
`;