import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "navigation/Navbar/";
import { Content, Row, Capa, Card, Quadro, ValoresStyled, Line, RowPrecos, ValoresStyled2, RowPrecos2, ContentMonitoramento, ContentPassos, RowButtons} from "./styles";
import { images, screenHeight } from "styles";
import Toggle from "components/Toggle";
import Button from "components/Button";
import Footer from "components/Footer";
import OutrosContatos from "components/OutrosContatos";
import Caroussel from "components/Caroussel";
import ReactGA from "react-ga";
import Passos from "./Passos";

class AMTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
    list: [
      {
      
      }
    ]
  }
};


  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-000000-01");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  action = (url, target) => {
    window.open(url, target ? target : "_blank");
  };
  scrollTop = () => {
    window.scroll(0, screenHeight);
  };

  render() {
    return (
      <Fragment>
        <Navbar page="/monitoramento" />
        <Content className="content">
          <Capa>
            <h1>AM Monitora - Serviço de Monitoramento</h1>
            <h2>Monitore novas infrações e o andamento de processos de suspensão e cassação.</h2>
            <img onClick={() => this.scrollTop()} src={images.mousePrimary} />
          </Capa>
          {this.state.list.map(lista => (
            <Fragment>
              <h3>Como funciona?</h3>
              <span>O AM Monitora funciona monitorando <b>automaticamente</b> a Carteira Nacional de Habilitação ou a placa dos seus veículos nos sistemas do Detran/RJ e Detran/RS.</span>
              <span><br/>Após iniciar o monitoramento, <b>você passa a receber alertas por e-mail de: </b></span>
              <span><br/>• Novas infrações em sua CNH</span>
              <span>• Novas infrações em seu veículo</span>
              <span>• Movimentações em processos de suspensão e cassação da CNH</span>
              <span><br/>O sistema trabalha <b>de forma automática</b>, buscando informações com frequência mensal, quinzenal ou semanal.</span>
            </Fragment>
          ))
          }

          <h3>Para quem se destina?</h3>
          <center><Row className="card">
            <Card>
            <h4>Pessoas Físicas</h4>
              <Quadro><span>• Representantes comerciais</span></Quadro>
              <Quadro><span>• Motoristas de Aplicativos</span></Quadro>
              <Quadro><span>• Taxistas</span></Quadro>
              <Quadro><span>• Caminhoneiros</span></Quadro>
              <Quadro><span>• Condutores eventuais</span></Quadro>
            </Card>
            <Card>
             <h4>Pessoas Jurídicas</h4>
              <Quadro><span>• Transportadoras</span></Quadro>
              <Quadro><span>• Locadoras de veículos</span></Quadro>
              <Quadro><span>• Revendas e garagens de automóveis</span></Quadro>
              <Quadro><span>• Demais empresas que possuem motoristas ou frota de veículos</span></Quadro>
            </Card>
          </Row>
          </center>

          <h3>Valores</h3>

          <RowPrecos>
      <ValoresStyled>
        <label>
          Pesquisa Semanal<br /> Uma placa ou CNH
        </label>
        <span className="obs">(4 Pesquisas por Mês)</span>
        {/*<span><br/></span>
        <label>Plano Mensal</label>*/}
        <span className="cifrao">R$ </span>
        <h3>9,</h3>
        <span className="cents">50</span>
        <span className="cents2">por mês</span>
      </ValoresStyled>
      <Line />
      <ValoresStyled>
        <label>
        Pesquisa Quinzenal<br /> Uma placa ou CNH
        </label>
        <span className="obs">(2 Pesquisas por Mês)</span>
        {/*<span><br/></span>
        <label>Plano Mensal</label>*/}
        <span className="cifrao">R$</span>
        <h3>7,</h3>
        <span className="cents">90</span>
        <span className="cents2">por mês</span>
      </ValoresStyled>
      <Line />
      <ValoresStyled>
      <label>
        Pesquisa Mensal<br /> Uma placa ou CNH
        </label>
        <span className="obs">(1 Pesquisa por Mês)</span>
        {/*<span><br/></span>
        <label>Plano Mensal</label>*/}
        <span className="cifrao">R$</span>
        <h3>5,</h3>
        <span className="cents">90</span>
        <span className="cents2">por mês</span>
      </ValoresStyled>
    </RowPrecos>

{/*

    <RowPrecos2>
      <ValoresStyled2>
      <label>Plano Semestral</label>
        <span className="cifrao">R$ </span>
        <h3>51,</h3>
        <span className="cents">30</span>
        <span><br/></span>
        <span className="cents2">(R$ 8,55 por mês)</span>
        
      </ValoresStyled2>

      <Line />
      <ValoresStyled2>

        <label>Plano Semestral</label>
        <span className="cifrao">R$</span>
        <h3>42,</h3>
        <span className="cents">60</span>
        <span><br/></span>
        <span className="cents2">(R$ 7,10 por mês)</span>
      </ValoresStyled2>
      
      <Line />

      <ValoresStyled2>
        <label>Plano Semestral</label>
        <span className="cifrao">R$</span>
        <h3>31,</h3>
        <span className="cents">90</span>
        <span><br/></span>
        <span className="cents2">(R$ 5,32 por mês)</span>
      </ValoresStyled2>
    </RowPrecos2>


    <RowPrecos2>
      <ValoresStyled2>
      <label>Plano Anual</label>
        <span className="cifrao">R$ </span>
        <h3>94,</h3>
        <span className="cents">60</span>
        <span><br/></span>
        <span className="cents2">(R$ 7,89 por mês)</span>
        
      </ValoresStyled2>

      <Line />
      <ValoresStyled2>

        <label>Plano Anual</label>
        <span className="cifrao">R$</span>
        <h3>78,</h3>
        <span className="cents">70</span>
        <span><br/></span>
        <span className="cents2">(R$ 6,56 por mês)</span>
      </ValoresStyled2>
      
      <Line />

      <ValoresStyled2>
        <label>Plano Anual</label>
        <span className="cifrao">R$</span>
        <h3>58,</h3>
        <span className="cents">80</span>
        <span><br/></span>
        <span className="cents2">(R$ 4,90 por mês)</span>
      </ValoresStyled2>
    </RowPrecos2>

    */ }
    <span><br/><br/></span>
    <h3>Inicie seu monitoramento agora mesmo!</h3>
    <ContentMonitoramento>
            <Passos number="1º Passo" />
            <ContentPassos>     

            <span className="info-text">
            Ao contratar, você concorda com nosso{" "}
            <Link className="link-text" to="../externalfiles/contratoservico.pdf" target="_blank">
              Contrato de Prestação de Serviços
            </Link>{" "}
            e com nossa
            <Link className="link-text" to="../externalfiles/politicadeprivacidade.pdf" target="_blank">
              Política de Privacidade
            </Link>{" "}.
          </span>

    <iframe
          className="iframe"
          title="Cadastro de Monitoramento"
          src="https://docs.google.com/forms/d/e/1FAIpQLSeZ7JKWF4Tjw9hzrpXS7OQUL51Br2kU-Skw5ODe5fucHqDf0Q/viewform?embedded=true"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Carregando…
        </iframe>
        
        <Button
          title="Clique aqui caso não consiga visualizar o formulário."
          bordered
          onClick={() =>
            this.action(
              "https://docs.google.com/forms/d/e/1FAIpQLSeZ7JKWF4Tjw9hzrpXS7OQUL51Br2kU-Skw5ODe5fucHqDf0Q/viewform"
            )
          }
        /></ContentPassos>
</ContentMonitoramento>
     
         
      <ContentMonitoramento>
            <Passos number="2º Passo" />
        <ContentPassos>
          <h3>Selecione por qual pesquisa pagar</h3>
          
          <RowButtons>
          <ValoresStyled><label>Pesquisa Semanal</label>
          <Button
            title="R$ 9,50"
            bordered
            onClick={() =>
              this.action(
                "http://mpago.la/197LLfb"
              )
            }
          /></ValoresStyled><ValoresStyled><Line/></ValoresStyled>

          <ValoresStyled><label>Pesquisa Quinzenal</label>
          <Button
            title="R$ 7,90"
            bordered
            onClick={() =>
              this.action(
                "http://mpago.la/1zH7jcz"
              )
            }
          /></ValoresStyled><ValoresStyled><Line /></ValoresStyled>

            <ValoresStyled><label>Pesquisa Mensal</label>
          <Button
            title="R$ 5,90"
            bordered
            onClick={() =>
              this.action(
                "http://mpago.la/1RgE9KD"
              )
            }
          /></ValoresStyled>

          </RowButtons>
          <span className="texto1"><br/>Deseja pagar por mais do que um monitoramento?<br/></span>
          <Button
          title="Entre em contato e peça um link de pagamento"
          bordered
          onClick={() =>
            this.action(
              "./contato"
            )
          }
        />
        </ContentPassos>
        
      </ContentMonitoramento>

      <ContentMonitoramento>
            <Passos number="3º Passo" />
              <ContentPassos>
                <h3>Fique tranquilo!</h3>
                <span>Em cerca de 24 horas os seus dados estarão cadastrados em nossa plataforma.<br/><br/>A partir disso, você receberá alertas por e-mail ou WhatsApp caso haja alguma processo de trânsito (autuação, suspensão ou cassação) novo ou em andamento em seu veículo ou CNH.</span>     
              </ContentPassos>
      </ContentMonitoramento>
          <OutrosContatos
            centered
            title="Saiba Mais"
            desc="Quer saber mais sobre nós e nosso trabalho? Acesse e nos siga em nosso canais."
          />
          <Footer />
        </Content>
      </Fragment>
    );
  }
}

export default AMTracking;
