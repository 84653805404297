import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import {ContentStyled, RowStyled} from "components/Styled";
import {colors} from 'styles'





export const Content=styled(ContentStyled)`
  align-items: flex-start;
  z-index: 2;

  h2{
    color:${colors.dark};
    margin-bottom: 60px;
  }
  h1{
    margin-top: 30px;
  }
  h3{
    margin-bottom: 30px;
  }
 
    
  
`;


export const Row=styled(RowStyled)`

  margin-bottom: 15px;
`;